<template>
  <viewcard--c
    :title="
      $route.params.id > 0
        ? 'Atualizar Período de Execução'
        : 'Cadastrar Período de Execução'
    "
    :btsave="$route.params.id > 0 ? btedit : btcreate"
    :btdelete="null"
    :btback="{}"
    :busy="loading"
    @clicked-save="validationForm"
    @clicked-delete="
      {
      }
    "
  >
    <hr class="p-0 m-0 mb-1" />
    <validation-observer ref="periodsRules">
      <b-form @submit.prevent>
        <b-row>
          <b-col md="6">
            <b-form-group label="Nome *">
              <validation-provider
                #default="{ errors }"
                name="Nome do Período"
                rules="required"
              >
                <b-form-input
                  v-model="record.name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Nome do período"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Ativo">
              <b-form-checkbox
                class="custom-control-success mt-1"
                name="check-button"
                switch
                v-model="record.active"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group class="mt-1" style="text-align: center">
              <b-row>
                <b-col cols="12">
                  <label class="mr-2 mb-1 col-form-label">segunda-feira</label>
                  <b-button
                    size="sm"
                    class="mr-2 mb-0"
                    variant="outline-danger"
                    :id="`btn-apagar-segunda`"
                    @click="apagarHorarioDia('segunda')"
                  >
                    <feather-icon icon="DeleteIcon" />
                    <b-tooltip
                      triggers="hover"
                      :target="`btn-apagar-segunda`"
                      title="Limpar horários de segunda-feira"
                      :delay="{ show: 100, hide: 50 }"
                    />
                  </b-button>
                  <b-button
                    size="sm"
                    class="mr-2 mb-0"
                    variant="outline-warning"
                    :id="`btn-copiar-segunda`"
                    @click="copiarParaTodosOsDias('segunda')"
                  >
                    <feather-icon icon="CopyIcon" />
                    <b-tooltip
                      triggers="hover"
                      :target="`btn-copiar-segunda`"
                      title="Copiar para todos os dias"
                      :delay="{ show: 100, hide: 50 }"
                    />
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <b-form-group>
                    <flat-pickr
                      v-model="record.monday_start"
                      placeholder="Início"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: 'H:i',
                        time_24hr: true,
                      }"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group>
                    <flat-pickr
                      v-model="record.monday_end"
                      placeholder="Fim"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: 'H:i',
                        time_24hr: true,
                      }"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group class="mt-1" style="text-align: center">
              <b-row>
                <b-col cols="12">
                  <label class="mr-2 mb-1 col-form-label">terça-feira</label>
                  <b-button
                    size="sm"
                    class="mr-2 mb-0"
                    variant="outline-danger"
                    :id="`btn-apagar-terca`"
                    @click="apagarHorarioDia('terça')"
                  >
                    <feather-icon icon="DeleteIcon" />
                    <b-tooltip
                      triggers="hover"
                      :target="`btn-apagar-terca`"
                      title="Limpar horários de terça-feira"
                      :delay="{ show: 100, hide: 50 }"
                    />
                  </b-button>
                  <b-button
                    size="sm"
                    class="mr-2 mb-0"
                    variant="outline-warning"
                    :id="`btn-copiar-terca`"
                    @click="copiarParaTodosOsDias('terça')"
                  >
                    <feather-icon icon="CopyIcon" />
                    <b-tooltip
                      triggers="hover"
                      :target="`btn-copiar-terca`"
                      title="Copiar para todos os dias"
                      :delay="{ show: 100, hide: 50 }"
                    />
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <b-form-group>
                    <flat-pickr
                      v-model="record.tuesday_start"
                      placeholder="Início"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: 'H:i',
                        time_24hr: true,
                      }"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group>
                    <flat-pickr
                      v-model="record.tuesday_end"
                      placeholder="Fim"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: 'H:i',
                        time_24hr: true,
                      }"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>

          <b-col cols="4">
            <b-form-group class="mt-1" style="text-align: center">
              <b-row>
                <b-col cols="12">
                  <label class="mr-2 mb-1 col-form-label">quarta-feira</label>
                  <b-button
                    size="sm"
                    class="mr-2 mb-0"
                    variant="outline-danger"
                    :id="`btn-apagar-quarta`"
                    @click="apagarHorarioDia('quarta')"
                  >
                    <feather-icon icon="DeleteIcon" />
                    <b-tooltip
                      triggers="hover"
                      :target="`btn-apagar-quarta`"
                      title="Limpar horários de quarta-feira"
                      :delay="{ show: 100, hide: 50 }"
                    />
                  </b-button>
                  <b-button
                    size="sm"
                    class="mr-2 mb-0"
                    variant="outline-warning"
                    :id="`btn-copiar-quarta`"
                    @click="copiarParaTodosOsDias('quarta')"
                  >
                    <feather-icon icon="CopyIcon" />
                    <b-tooltip
                      triggers="hover"
                      :target="`btn-copiar-quarta`"
                      title="Copiar para todos os dias"
                      :delay="{ show: 100, hide: 50 }"
                    />
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <b-form-group>
                    <flat-pickr
                      v-model="record.wednesday_start"
                      placeholder="Início"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: 'H:i',
                        time_24hr: true,
                      }"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group>
                    <flat-pickr
                      v-model="record.wednesday_end"
                      placeholder="Fim"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: 'H:i',
                        time_24hr: true,
                      }"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>

          <b-col cols="4">
            <b-form-group class="mt-1" style="text-align: center">
              <b-row>
                <b-col cols="12">
                  <label class="mr-2 mb-1 col-form-label">quinta-feira</label>
                  <b-button
                    size="sm"
                    class="mr-2 mb-0"
                    variant="outline-danger"
                    :id="`btn-apagar-quinta`"
                    @click="apagarHorarioDia('quinta')"
                  >
                    <feather-icon icon="DeleteIcon" />
                    <b-tooltip
                      triggers="hover"
                      :target="`btn-apagar-quinta`"
                      title="Limpar horários de quinta-feira"
                      :delay="{ show: 100, hide: 50 }"
                    />
                  </b-button>
                  <b-button
                    size="sm"
                    class="mr-2 mb-0"
                    variant="outline-warning"
                    :id="`btn-copiar-quinta`"
                    @click="copiarParaTodosOsDias('quinta')"
                  >
                    <feather-icon icon="CopyIcon" />
                    <b-tooltip
                      triggers="hover"
                      :target="`btn-copiar-quinta`"
                      title="Copiar para todos os dias"
                      :delay="{ show: 100, hide: 50 }"
                    />
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <b-form-group>
                    <flat-pickr
                      v-model="record.thursday_start"
                      placeholder="Início"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: 'H:i',
                        time_24hr: true,
                      }"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group>
                    <flat-pickr
                      v-model="record.thursday_end"
                      placeholder="Fim"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: 'H:i',
                        time_24hr: true,
                      }"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>

          <b-col cols="4">
            <b-form-group class="mt-1" style="text-align: center">
              <b-row>
                <b-col cols="12">
                  <label class="mr-2 mb-1 col-form-label">sexta-feira</label>
                  <b-button
                    size="sm"
                    class="mr-2 mb-0"
                    variant="outline-danger"
                    :id="`btn-apagar-sexta`"
                    @click="apagarHorarioDia('sexta')"
                  >
                    <feather-icon icon="DeleteIcon" />
                    <b-tooltip
                      triggers="hover"
                      :target="`btn-apagar-sexta`"
                      title="Limpar horários de sexta-feira"
                      :delay="{ show: 100, hide: 50 }"
                    />
                  </b-button>
                  <b-button
                    size="sm"
                    class="mr-2 mb-0"
                    variant="outline-warning"
                    :id="`btn-copiar-sexta`"
                    @click="copiarParaTodosOsDias('sexta')"
                  >
                    <feather-icon icon="CopyIcon" />
                    <b-tooltip
                      triggers="hover"
                      :target="`btn-copiar-sexta`"
                      title="Copiar para todos os dias"
                      :delay="{ show: 100, hide: 50 }"
                    />
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <b-form-group>
                    <flat-pickr
                      v-model="record.friday_start"
                      placeholder="Início"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: 'H:i',
                        time_24hr: true,
                      }"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group>
                    <flat-pickr
                      v-model="record.friday_end"
                      placeholder="Fim"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: 'H:i',
                        time_24hr: true,
                      }"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>

          <b-col cols="4">
            <b-form-group class="mt-1" style="text-align: center">
              <b-row>
                <b-col cols="12">
                  <label class="mr-2 mb-1 col-form-label">sábado</label>
                  <b-button
                    size="sm"
                    class="mr-2 mb-0"
                    variant="outline-danger"
                    :id="`btn-apagar-sabado`"
                    @click="apagarHorarioDia('sabado')"
                  >
                    <feather-icon icon="DeleteIcon" />
                    <b-tooltip
                      triggers="hover"
                      :target="`btn-apagar-sabado`"
                      title="Limpar horários de sábado"
                      :delay="{ show: 100, hide: 50 }"
                    />
                  </b-button>
                  <b-button
                    size="sm"
                    class="mr-2 mb-0"
                    variant="outline-warning"
                    :id="`btn-copiar-sabado`"
                    @click="copiarParaTodosOsDias('sabado')"
                  >
                    <feather-icon icon="CopyIcon" />
                    <b-tooltip
                      triggers="hover"
                      :target="`btn-copiar-sabado`"
                      title="Copiar para todos os dias"
                      :delay="{ show: 100, hide: 50 }"
                    />
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <b-form-group>
                    <flat-pickr
                      v-model="record.saturday_start"
                      placeholder="Início"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: 'H:i',
                        time_24hr: true,
                      }"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group>
                    <flat-pickr
                      v-model="record.saturday_end"
                      placeholder="Fim"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: 'H:i',
                        time_24hr: true,
                      }"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>

          <b-col cols="4">
            <b-form-group class="mt-1" style="text-align: center">
              <b-row>
                <b-col cols="12">
                  <label class="mr-2 mb-1 col-form-label">domingo</label>
                  <b-button
                    size="sm"
                    class="mr-2 mb-0"
                    variant="outline-danger"
                    :id="`btn-apagar-domingo`"
                    @click="apagarHorarioDia('domingo')"
                  >
                    <feather-icon icon="DeleteIcon" />
                    <b-tooltip
                      triggers="hover"
                      :target="`btn-apagar-domingo`"
                      title="Limpar horários de domingo"
                      :delay="{ show: 100, hide: 50 }"
                    />
                  </b-button>
                  <b-button
                    size="sm"
                    class="mr-2 mb-0"
                    variant="outline-warning"
                    :id="`btn-copiar-domingo`"
                    @click="copiarParaTodosOsDias('domingo')"
                  >
                    <feather-icon icon="CopyIcon" size="14" />
                    <b-tooltip
                      triggers="hover"
                      :target="`btn-copiar-domingo`"
                      title="Copiar para todos os dias"
                      :delay="{ show: 100, hide: 50 }"
                    />
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <b-form-group>
                    <flat-pickr
                      v-model="record.sunday_start"
                      placeholder="Início"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: 'H:i',
                        time_24hr: true,
                      }"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group>
                    <flat-pickr
                      v-model="record.sunday_end"
                      placeholder="Fim"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: 'H:i',
                        time_24hr: true,
                      }"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </viewcard--c>
</template>
<script>
import _jobTypePeriodService from "@/services/audiovisual/job-type-period-service";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import pt_br from "vee-validate/dist/locale/pt_BR.json";
import { required } from "@validations";
import { BFormFile } from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormFile,
  },
  data() {
    return {
      btedit: { permission: `permission.audiovisual.demands.period.edit` },
      btcreate: { permission: `permission.audiovisual.demands.period.create` },
      btdelete: { permission: `permission.audiovisual.demands.period.delete` },
      loading: false,
      record: {
        id: 0,
        name: "",
        active: true,
        monday_start: "",
        monday_end: "",
        tuesday_start: "",
        tuesday_end: "",
        wednesday_start: "",
        wednesday_end: "",
        thursday_start: "",
        thursday_end: "",
        friday_start: "",
        friday_end: "",
        saturday_start: "",
        saturday_end: "",
        sunday_start: "",
        sunday_end: "",
      },
    };
  },
  created() {
    localize("pt_BR", pt_br);
    this.getRecord();
  },
  methods: {
    validationForm() {
      this.$refs.periodsRules.validate().then((success) => {
        if (success) {
          this.save();
        }
      });
    },
    getRecord() {
      if (this.$route.params.id > 0) {
        this.loading = true;
        _jobTypePeriodService
          .find(this.$route.params.id)
          .then((res) => {
            this.record = res.content;
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.loading = false));
      }
    },
    save() {
      const payload = { data: { ...this.record } };

      //promisse
      const _createOrUpdate =
        this.record.id <= 0
          ? _jobTypePeriodService.create(payload)
          : _jobTypePeriodService.update(payload);

      this.loading = true;
      _createOrUpdate
        .then(() => {
          this.$utils.toast("Notificação", "Registro salvo com sucesso.");
          this.$router.go(-1);
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    apagarHorarioDia(dia) {
      if (dia == "segunda") {
        this.record.monday_start = null;
        this.record.monday_end = null;
      } else if (dia == "terça") {
        this.record.tuesday_start = null;
        this.record.tuesday_end = null;
      } else if (dia == "quarta") {
        this.record.wednesday_start = null;
        this.record.wednesday_end = null;
      } else if (dia == "quinta") {
        this.record.thursday_start = null;
        this.record.thursday_end = null;
      } else if (dia == "sexta") {
        this.record.friday_start = null;
        this.record.friday_end = null;
      } else if (dia == "sabado") {
        this.record.saturday_start = null;
        this.record.saturday_end = null;
      } else if (dia == "domingo") {
        this.record.sunday_start = null;
        this.record.sunday_end = null;
      }
    },
    copiarParaTodosOsDias(dia) {
      var start = "";
      var end = "";

      if (dia == "segunda") {
        start = this.record.monday_start;
        end = this.record.monday_end;
      } else if (dia == "terça") {
        start = this.record.tuesday_start;
        end = this.record.tuesday_end;
      } else if (dia == "quarta") {
        start = this.record.wednesday_start;
        end = this.record.wednesday_end;
      } else if (dia == "quinta") {
        start = this.record.thursday_start;
        end = this.record.thursday_end;
      } else if (dia == "sexta") {
        start = this.record.friday_start;
        end = this.record.friday_end;
      } else if (dia == "sabado") {
        start = this.record.saturday_start;
        end = this.record.saturday_end;
      } else if (dia == "domingo") {
        start = this.record.sunday_start;
        end = this.record.sunday_end;
      }

      this.record.monday_start = start;
      this.record.monday_end = end;

      this.record.tuesday_start = start;
      this.record.tuesday_end = end;

      this.record.wednesday_start = start;
      this.record.wednesday_end = end;

      this.record.thursday_start = start;
      this.record.thursday_end = end;

      this.record.friday_start = start;
      this.record.friday_end = end;

      this.record.saturday_start = start;
      this.record.saturday_end = end;

      this.record.sunday_start = start;
      this.record.sunday_end = end;
    },
  },
};
</script>